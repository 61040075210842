import React from 'react'
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Role from 'src/utils/role'

const RoleGuard = ({ children }) => {
    const { isAuthenticated, role } = useAuth();

    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }

    if (isAuthenticated && role !== Role.Superuser) {
        return <Redirect to="/401" />;
    }

    return (
        <>
            {children}
        </>
    )
}

RoleGuard.propTypes = {
    children: PropTypes.node,
    role: PropTypes.string
};

export default RoleGuard;