import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import { API_URL } from 'src/constants';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  services: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setSessionUser = (user, services) => {
  if (user && services) {
    localStorage.setItem('user', user);
    localStorage.setItem('services', services);
  } else if (user) {
    localStorage.setItem('user', user);
  } else if (services) {
    localStorage.setItem('services', services);
  } else {
    localStorage.removeItem('user');
    localStorage.removeItem('services');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, services, role } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        services,
        role
      };
    }
    case 'LOGIN': {
      const { user, services } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        services,
        role: user.userType
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        services: null,
        role: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        role: user.userType
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (userName, password) => {
    const response = await axios.post(`${API_URL}/login`, { userName, password });
    const { accessToken, user, services } = response.data;

    setSession(accessToken);
    setSessionUser(JSON.stringify(user), JSON.stringify(services));
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        services,
        role: user.userType
      }
    });
  };

  const logout = () => {
    setSession(null);
    setSessionUser(null);
    dispatch({ type: 'LOGOUT' });
  };

  // const register = async (email, name, password) => {
  //   const response = await axios.post('/api/account/register', {
  //     email,
  //     name,
  //     password
  //   });
  //   const { accessToken, user } = response.data;

  //   window.localStorage.setItem('accessToken', accessToken);

  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user
  //     }
  //   });
  // };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const services = window.localStorage.getItem('services');
        
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const user = jwtDecode(accessToken);
          const setServices = services;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: user,
              services: setServices,
              role: user.userType
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              services: null,
              role: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            role: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;