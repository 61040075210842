import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import RoleGuard from 'src/components/RoleGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/dashboard',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/dashboard/about',
        component: lazy(() => import('src/views/about/AboutView'))
      },
      {
        exact: true,
        path: '/dashboard/management/brands',
        component: lazy(() => import('src/views/brand/BrandListView'))
      },
      {
        exact: true,
        path: '/dashboard/management/brands/add',
        component: lazy(() => import('src/views/brand/BrandAddView'))
      },
      {
        exact: true,
        path: '/dashboard/management/brands/:brandId',
        component: lazy(() => import('src/views/brand/BrandDetailsView'))
      },
      {
        exact: true,
        path: '/dashboard/management/brands/:brandId/edit',
        component: lazy(() => import('src/views/brand/BrandEditView'))
      },
      {
        exact: true,
        path: '/dashboard/management/locations',
        component: lazy(() => import('src/views/location/LocationListView'))
      },
      {
        exact: true,
        path: '/dashboard/management/locations/add',
        component: lazy(() => import('src/views/location/LocationAddView'))
      },
      {
        exact: true,
        path: '/dashboard/management/locations/:locationId',
        component: lazy(() => import('src/views/location/LocationDetailsView'))
      },
      {
        exact: true,
        path: '/dashboard/management/locations/:locationId/edit',
        component: lazy(() => import('src/views/location/LocationEditView'))
      },
      {
        exact: true,
        path: '/dashboard/management/users',
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/dashboard/management/users/add',
        component: lazy(() => import('src/views/user/UserAddView'))
      },
      {
        exact: true,
        path: '/dashboard/management/users/:userId',
        component: lazy(() => import('src/views/user/UserDetailsView'))
      },
      {
        exact: true,
        path: '/dashboard/management/users/:userId/edit',
        component: lazy(() => import('src/views/user/UserEditView'))
      },
      {
        exact: true,
        role: 'B',
        path: '/dashboard/apps/dispatch/drivers',
        component: lazy(() => import('src/views/dispatch/DriverListView'))
      },
      {
        exact: true,
        role: 'B',
        path: '/dashboard/apps/dispatch/drivers/add',
        component: lazy(() => import('src/views/dispatch/DriverAddView'))
      },
      {
        exact: true,
        role: 'B',
        path: '/dashboard/apps/dispatch/drivers/edit/:driverId',
        component: lazy(() => import('src/views/dispatch/DriverEditView'))
      },
      {
        exact: true,
        path: '/dashboard/apps/dispatch/orders',
        component: lazy(() => import('src/views/dispatch/OrderListView'))
      },
      {
        exact: true,
        role: 'B',
        path: '/dashboard/apps/dispatch/orders/add',
        component: lazy(() => import('src/views/dispatch/OrderAddView'))
      },
      {
        exact: true,
        role: 'B',
        path: '/dashboard/apps/dispatch/orders/edit/:orderId',
        component: lazy(() => import('src/views/dispatch/OrderEditView'))
      },
      {
        exact: true,
        path: '/dashboard/apps/waitlist/diningareas',
        component: lazy(() => import('src/views/waitlist/tableman/DinAreaListView'))
      },
      {
        exact: true,
        path: '/dashboard/apps/waitlist/diningareas/add',
        component: lazy(() => import('src/views/waitlist/tableman/DinAreaAddView'))
      },
      {
        exact: true,
        path: '/dashboard/apps/waitlist/diningareas/:dinAreaId',
        component: lazy(() => import('src/views/waitlist/tableman/DinAreaDetailsView'))
      },
      {
        exact: true,
        path: '/dashboard/apps/waitlist/diningareas/:dinAreaId/edit',
        component: lazy(() => import('src/views/waitlist/tableman/DinAreaEditView'))
      },
      {
        exact: true,
        path: '/dashboard/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/dashboard/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/dashboard/management',
        component: () => <Redirect to="/dashboard/management/brands" />
      },
      {
        exact: true,
        path: '/dashboard/checkmate',
        component: lazy(() => import('src/views/checkmate/CheckmateView'))
      },
      {
        exact: true,
        path: '/dashboard/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/dashboard/reports',
        component: () => <Redirect to="/dashboard/reports/dashboard" />
      },
      {
        exact: true,
        path: '/dashboard',
        component: () => <Redirect to="/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    guard: RoleGuard,
    routes: [
      {
        exact: true,
        role: "S",
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
