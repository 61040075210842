/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  User as UserIcon,
  Users as UsersIcon,
  Info as AboutIcon,
  Folder as DocsIcon,
  Grid as BrandIcon,
  MapPin as LocationIcon,
  FileText as WaitListIcon,
  Truck as DDIcon,
  CreditCard as MyGCOIcon,
  ArrowUpCircle as IRIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import usertypeToText from 'src/utils/usertypeToText';
import { API_URL } from 'src/constants';

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user, role, services } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const suSections = [
    {
      subheader: 'Home',
      items: [
        {
          title: 'Dashboard',
          icon: BarChartIcon,
          href: '/dashboard'
        },
        {
          title: 'Account',
          href: '/dashboard/account',
          icon: UserIcon
        }
      ]
    },
    {
      subheader: 'Management',
      items: [
        {
          title: 'Brands',
          icon: BrandIcon,
          href: '/dashboard/management/brands'
        },
        {
          title: 'Locations',
          icon: LocationIcon,
          href: '/dashboard/management/locations'
        },
        {
          title: 'Users',
          icon: UsersIcon,
          href: '/dashboard/management/users'
        },
      ]
    },
    {
      subheader: 'Applications',
      items: [
        {
          title: 'WaitList',
          href: '/dashboard/apps/waitlist',
          icon: WaitListIcon,
          items: [
            {
              title: 'Table Management',
              href: '/dashboard/apps/waitlist/diningareas'
            }
          ]
        },
        // {
        //   title: 'Delivery Dispatch',
        //   href: '/dashboard/apps/dispatch',
        //   icon: DDIcon,
        //   items: [
        //     {
        //       title: 'Dashboard',
        //       href: '/dashboard/apps/dispatch'
        //     },
        //     {
        //       title: 'Drivers',
        //       href: '/dashboard/apps/dispatch/drivers'
        //     },
        //     {
        //       title: 'Orders',
        //       href: '/dashboard/apps/dispatch/orders'
        //     }
        //   ]
        // },
        // {
        //   title: 'MyGCOnline',
        //   href: '/dashboard/social',
        //   icon: MyGCOIcon,
        //   items: [
        //     {
        //       title: 'Orders',
        //       href: '/dashboard/social/profile'
        //     },
        //     {
        //       title: 'Site Settings',
        //       href: '/dashboard/social/feed'
        //     }
        //   ]
        // },
        // {
        //   title: 'Im Ready!',
        //   href: '/dashboard/social',
        //   icon: IRIcon
        // }
      ]
    },
    // {
    //   subheader: 'Help',
    //   items: [
    //     {
    //       title: 'Documentation',
    //       href: '/docs',
    //       icon: DocsIcon
    //     },
    //     {
    //       title: 'Checkmate Test',
    //       href: '/dashboard/checkmate',
    //       icon: DocsIcon
    //     },
    //     {
    //       title: 'About',
    //       href: '/dashboard/about',
    //       icon: AboutIcon
    //     }
    //   ]
    // }
  ];
  
  const baSections = [
    {
      subheader: 'Home',
      items: [
        {
          title: 'Dashboard',
          icon: BarChartIcon,
          href: '/dashboard'
        },
        {
          title: 'Account',
          href: '/dashboard/account',
          icon: UserIcon
        }
      ]
    },
    {
      subheader: 'Management',
      items: [
      {
        title: 'My Brand',
        icon: BrandIcon,
        href: `/dashboard/management/brands/${user.brandId}`
      },
        {
          title: 'My Locations',
          icon: LocationIcon,
          href: `/dashboard/management/locations`
        },
      ]
    },
    {
      subheader: 'Help',
      items: [
        {
          title: 'About',
          href: '/dashboard/about',
          icon: AboutIcon
        }
      ]
    }
  ];

  const laSections = [
    {
      subheader: 'Home',
      items: [
        {
          title: 'Dashboard',
          icon: BarChartIcon,
          href: '/dashboard'
        },
        {
          title: 'Account',
          href: '/dashboard/account',
          icon: UserIcon
        }
      ]
    },
    {
      subheader: 'Management',
      items: [
        {
          title: 'My Location',
          icon: LocationIcon,
          href: `/dashboard/management/locations/${user.locationId}`
        },
        {
          title: 'WaitList',
          href: '/dashboard/apps/waitlist',
          icon: WaitListIcon,
          items: [
            {
              title: 'Table Management',
              href: '/dashboard/apps/waitlist/diningareas'
            }
          ]
        },
        // {
        //   title: 'Users',
        //   icon: UsersIcon,
        //   href: '/dashboard/management/users'
        // },
      ]
    },
    {
      subheader: 'Help',
      items: [
        {
          title: 'About',
          href: '/dashboard/about',
          icon: AboutIcon
        }
      ]
    }
  ];

  if (role === "S") {
    var sections = suSections;
  } else if (role === "B") {
    var sections = baSections;
  } else if (role === "L") {
    var sections = laSections;
  } else if (role === "H") {
    var sections = baSections;
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={`${API_URL}/logo/${user.brandId}` || ""}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/dashboard/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {usertypeToText(user.userType)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/dashboard/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
