export const APP_VERSION = '2.0.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  EBTF_DARK: 'EBTF_DARK',
  ONEPOS_LIGHT: 'ONEPOS_LIGHT'
};

export const API_URL = 'https://api.ebtf.cc';
