const usertypeToText = (userType) => {
    if (userType === "B") {
        return "Brand Administrator";
    } else if (userType === "S") {
        return "Superuser";
    } else if (userType === "L") {
        return "Location Administrator";
    } else if (userType === "H") {
        return "Location User";
    } else {
        return "User Type Unknown";
    }
};

export default usertypeToText;