import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/onePOS-logo-only.png"
      {...props}
    />
  );
}

export default Logo;
